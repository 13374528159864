<!-- lajna page -->
<template>
    <div class="section text-center" v-if="loading">
        <div class="spinner-border text-primary" role="status"></div>
      </div>
      <div class="home" v-if="!loading">
        <div class="section mb-2" v-if="banners.length">
          <Flicking :options="{ renderOnlyVisible: true, panelsPerView: 1, align: 'prev', circular: true }" :plugins="pluginsBanner">
            <div class="card-panel mt-2" v-for="banner in banners" :key="banner">
              <div class="card card-slider">
                <img :src="banner.image" class="card-img-top" alt="image">
                <!-- <div class="card-body">
                  <h5 class="card-title mal-text">{{ banner.title }}
                  </h5>
                  <p class="card-text mal-text" v-html="banner.content">
                  </p>
                </div> -->
              </div>
            </div>
          </Flicking>
        </div>
    </div>
    <div class="lajna-container">
        <div class="lajna-content">
            <!-- Event Slider -->
            <!-- <div class="slider-wrapper">
                <EventSlider />
            </div> -->
            <!-- Video Player Section -->
            <div class="video-section">
                <div class="video-container">
                    <iframe v-if="isPlaying" :src="youtubeEmbedUrl" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen class="video-iframe"></iframe>
                    <div v-else class="video-overlay" @click="playVideo">
                        <img :src="thumbnailUrl" alt="Video Thumbnail" class="video-placeholder" />
                        <div class="play-button-overlay">
                            <i></i>
                        </div>
                    </div>
                </div>
            </div>

            <hr>

            <!-- Navigation Buttons -->
            <div class="nav-buttons">
                <router-link to="/lajna/page/messages" class="nav-item">
                    <span class="nav-item-icon">✉️</span>
                    <span class="nav-item-text">MESSAGES</span>
                </router-link>
                <router-link to="/lajna/page/videos" class="nav-item">
                    <span class="nav-item-icon">🎥</span>
                    <span class="nav-item-text">VIDEOS</span>
                </router-link>
                <router-link to="/lajna/page/audios" class="nav-item">
                    <span class="nav-item-icon">🎧</span>
                    <span class="nav-item-text">AUDIOS</span>
                </router-link>
                <router-link to="/lajna/page/books" class="nav-item">
                    <span class="nav-item-icon">📚</span>
                    <span class="nav-item-text">E BOOKS</span>
                </router-link>
                <router-link to="/lajna/page/chats" class="nav-item">
                    <span class="nav-item-icon">💬</span>
                    <span class="nav-item-text">CHAT ROOM</span>
                </router-link>
                <router-link to="/lajna/page/links" class="nav-item">
                    <span class="nav-item-icon">🔗</span>
                    <span class="nav-item-text">LINKS</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
// import EventSlider from '../../components/EventSlider.vue';
import { AutoPlay } from "@egjs/flicking-plugins";

export default {
    components: {
        // EventSlider
    },
    setup() {
        // const eventSlides = ref([
        //     {
        //         title: 'NEXT DAEE MEET',
        //         date: 'NOV. 11 SUNDAY',
        //         buttonText: 'Register Now'
        //     },
        // ]);
        const banners = ref([
            {
                image: 'https://guide.msoftit.com/uploads/image/1736748723.jpeg',
            },
            {
                image :'https://guide.msoftit.com/uploads/image/1736748083.jpeg'
            }
        ]);
        const pluginsBanner = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false })];
        const handleEventRegistration = (event) => {
            console.log('Registration requested for event:', event);
        };
        const videoUrl = ref("https://www.youtube.com/watch?v=jToDPADMMPY");
        const isPlaying = ref(false);

        const videoId = computed(() => videoUrl.value.split("v=")[1]);
        const youtubeEmbedUrl = computed(() => `https://www.youtube.com/embed/${videoId.value}?autoplay=1`);
        const thumbnailUrl = computed(() => `https://img.youtube.com/vi/${videoId.value}/maxresdefault.jpg`);

        

        const playVideo = () => {
            isPlaying.value = true;
        };

        return {
            pluginsBanner,
            // eventSlides,
            banners,
            handleEventRegistration,
            videoUrl,
            isPlaying,
            youtubeEmbedUrl,
            thumbnailUrl,
            playVideo
        };
    }
}
</script>

<style>
.lajna-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.video-overlay {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.play-button-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.play-button-overlay i {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 0 12px 20px;
    border-color: transparent transparent transparent #333;
    margin-left: 4px;
    /* Slight adjustment to center the triangle */
}

.video-placeholder {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.play-button-overlay:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.lajna-content {
    width: 100%;
    max-width: 700px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 2rem;
}

.slider-wrapper {
    margin-bottom: 2rem;
}

.video-section {
    margin: 0rem 0;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.video-container {
    position: relative;
    width: 100%;
    background-color: black;
    aspect-ratio: 16/9;
}

.video-iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.video-placeholder {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.video-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.control-btn {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    transition: background-color 0.2s ease;
}

.control-btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.control-icon {
    font-size: 1.2rem;
    display: block;
}

.timeline {
    flex: 1;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    overflow: hidden;
}

.progress-bar {
    width: 30%;
    height: 100%;
    background-color: #ffd700;
    border-radius: 2px;
    transition: width 0.2s ease;
}

.nav-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1.5rem;
    margin: 2rem 0;
    padding: 0.5rem;
}

.nav-item {
    background-color: #f5f7fa;
    border: none;
    padding: 1.25rem 1rem;
    border-radius: 15px;
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #4a5568;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}

.nav-item:hover {
    background-color: #edf2f7;
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.nav-item-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.nav-item-text {
    font-size: 0.7rem;
    font-weight: 700;
}

@media (max-width: 640px) {
    .lajna-container {
        padding: 15px;
    }

    .lajna-content {
        padding: 0px;
    }

    .nav-buttons {
        gap: 0.5rem;
        padding: 0.25rem;
    }

    .nav-item {
        padding: 0.75rem 0.5rem;
    }

    .nav-item-icon {
        font-size: 1.25rem;
        margin-bottom: 0.25rem;
    }

    .nav-item-text {
        font-size: 0.65rem;
    }
}
</style>