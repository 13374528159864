<template>
    <div class="lajna-container">
        <div class="lajna-content">
            <!-- Event Slider Section -->
            <div class="slider-wrapper">
                <EventSlider />
            </div>

            <CategoryFilter />

            <hr>

            <!-- No Messages Message -->
            <div v-if="!messages || messages.length === 0" class="no-messages-message">
                <div class="message-content">
                    <i class="fas fa-envelope-open"></i>
                    <h3>Message is not Available</h3>
                </div>
            </div>

            <!-- Message Cards -->
            <div class="message-cards">
                <div v-for="message in messages" :key="message.id" class="message-card">
                    <div class="message-header">
                        <h3 class="message-title">{{ message.title }}</h3>
                        <span class="message-date">{{ message.date }}</span>
                    </div>
                    <p class="message-preview">{{ message.preview }}</p>
                    <div class="message-footer">
                        <button class="read-more-btn">
                            Read More
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CategoryFilter from '../../components/CategoryFilter.vue';
import EventSlider from '../../components/EventSlider.vue';

export default {
    name: 'EventSliderWithNav',
    data() {
        return {
            messages: [
                {
                    id: 1,
                    title: 'Daee Meet',
                    preview: 'السلام عليكم ورحمة الله Daee Meet will be held on 9th February 2025 at Malappuram إن شاء الله'
                },
                // {
                //     id: 2,
                //     title: 'Message 2',
                //     preview: 'തിരക്കിട്ട ജീവിതത്തിനിടയിൽ പലതരത്തിലുമുള്ള പ്രതിഭാസങ്ങൾ കലരുന്ന ഒരു ഒഴുക്കിന് തീർപ്പുകാണാൻ ശ്രമിക്കുന്നത്.'
                // }
            ]
        }
    },
    components: {
        CategoryFilter,
        EventSlider
    }
}
</script>

<style scoped>
.message-cards {
    margin-top: 1rem;
}

.message-card {
    background: white;
    border-radius: 0.75rem;
    padding: 1.25rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
}

.message-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #1a237e;
    margin: 0;
}

.message-date {
    font-size: 0.85rem;
    color: #666;
}

.message-preview {
    color: #444;
    font-size: 0.95rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.message-footer {
    display: flex;
    justify-content: flex-start;
}

.read-more-btn {
    background: #1a237e;
    color: white;
    border: none;
    padding: 0.5rem 1.25rem;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.read-more-btn:hover {
    background: #2a337e;
}

.no-messages-message {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 270px;
    background: #f8f9fa;
    border-radius: 8px;
    margin: 1rem 0;
    border: 1px dashed #dee2e6;
}

.message-content {
    text-align: center;
    color: #6c757d;
}

.message-content i {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.message-content h3 {
    font-size: 1.2rem;
    margin: 0;
    font-weight: 500;
}

.bottom-button {
    display: block;
    width: 100%;
    margin: 1rem auto;
    padding: 0.75rem 1.5rem;
    background: #0d4f17;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    text-align: center;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .message-title {
        font-size: 1rem;
    }

    .message-date {
        font-size: 0.8rem;
    }

    .message-preview {
        font-size: 0.9rem;
    }

    .read-more-btn {
        font-size: 0.85rem;
        padding: 0.4rem 1rem;
    }
}
</style>