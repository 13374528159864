<template>
    <div class="lajna-container">
        <div class="lajna-content">
            <!-- Event Slider Section -->
            <div class="slider-wrapper">
                <EventSlider />
            </div>

            <CategoryFilter />

            <hr>

            <!-- Check the console log here -->
            <div v-if="debugVideos"></div>

            <!-- No Videos Message -->
            <div v-if="!videos || videos.length === 0" class="no-videos-message">
                <div class="message-content">
                    <i class="fas fa-video-slash"></i>
                    <h3>Video is not Available</h3>
                </div>
            </div>

            <!-- Video Grid -->
            <div v-else class="video-grid">
                <div v-for="video in videos" :key="video.id" class="video-card" @click="openVideoModal(video)">
                    <div class="video-thumbnail">
                        <img :src="video.thumbnail" :alt="video.title">
                        <div class="play-overlay">
                            <div class="play-icon">
                                <div class="play-symbol"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div v-if="showModal" class="modal-overlay" @click.self="closeVideoModal">
                <div class="modal-wrapper">
                    <div class="modal-inner">
                        <div class="video-wrapper">
                            <iframe v-if="selectedVideo" :src="getEmbedUrl(selectedVideo.videoId)"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen class="video-frame"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CategoryFilter from '../../components/CategoryFilter.vue';
import EventSlider from '../../components/EventSlider.vue';

export default {
    data() {
        return {
            videos: [
                {
                    id: 1,
                    videoId: 'jToDPADMMPY',
                    thumbnail: `https://img.youtube.com/vi/jToDPADMMPY/maxresdefault.jpg`,
                },
                // {
                //     id: 2,
                //     videoId: '2vUmKLPbexs',
                //     thumbnail: `https://img.youtube.com/vi/2vUmKLPbexs/maxresdefault.jpg`,
                // },
                // {
                //     id: 3,
                //     videoId: 'AJJRtaZkNxA',
                //     thumbnail: `https://img.youtube.com/vi/AJJRtaZkNxA/maxresdefault.jpg`,
                // },
                // {
                //     id: 4,
                //     videoId: 's9hOBMKcrvc',
                //     thumbnail: `https://img.youtube.com/vi/s9hOBMKcrvc/maxresdefault.jpg`,
                // },
                // {
                //     id: 5,
                //     videoId: 'OC-Hb-Lx59s',
                //     thumbnail: `https://img.youtube.com/vi/OC-Hb-Lx59s/maxresdefault.jpg`,
                // },
                // {
                //     id: 6,
                //     videoId: 'BbdA9XFwkVc',
                //     thumbnail: `https://img.youtube.com/vi/BbdA9XFwkVc/maxresdefault.jpg`,
                // }
            ],
            showModal: false,
            selectedVideo: null,
        }
    },
    created() {
        // Add this to debug
        console.log('Videos array:', this.videos);
        console.log('Videos length:', this.videos.length);
    },
    methods: {
        getEmbedUrl(videoId) {
            return `https://www.youtube.com/embed/${videoId}?autoplay=1`
        },
        openVideoModal(video) {
            this.selectedVideo = video;
            this.showModal = true;
        },
        closeVideoModal() {
            this.showModal = false;
            this.selectedVideo = null;
        }
    },
    components: {
        CategoryFilter,
        EventSlider
    }
}
</script>

<style>
.video-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    position: relative;
}

.video-card {
    background: white;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    cursor: pointer;
}

.video-card:hover {
    transform: translateY(-2px);
}

.video-thumbnail {
    position: relative;
    aspect-ratio: 16/9;
    overflow: hidden;
}

.video-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.play-icon {
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.685);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #333;
    transition: transform 0.2s ease;
}

.video-card:hover .play-icon {
    transform: scale(1.1);
}

.play-symbol {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 0 12px 20px;
    border-color: transparent transparent transparent #333;
    margin-left: 4px;
}

.video-info {
    padding: 0.75rem;
}

.video-title {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.modal-wrapper {
    position: relative;
    width: 80%;
    max-width: 800px;
    z-index: 1000;
    padding: 20px;
}

.modal-inner {
    position: relative;
    width: 100%;
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 Aspect Ratio */
    height: 0;
    overflow: hidden;
    background: black;
    border-radius: 8px;
}

.video-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.no-videos-message {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 270px;
    background: #f8f9fa;
    border-radius: 8px;
    margin: 1rem 0;
}

.message-content {
    text-align: center;
    color: #6c757d;
}

.message-content i {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.message-content h3 {
    font-size: 1.2rem;
    margin: 0;
    font-weight: 500;
}

@media (max-width: 768px) {
    .video-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .modal-wrapper {
        width: 90%;
        padding: 10px;
    }
}
</style>