<template>
    <div class="lajna-container">
        <div class="lajna-content">
            <!-- Event Slider Section -->
            <div class="slider-wrapper">
                <EventSlider />
            </div>

            <CategoryFilter />

            <hr>

             <!-- No Messages Message -->
             <div v-if="!documents || documents.length === 0" class="no-books-message">
                <div class="message-content">
                    <i class="fas fa-envelope-open"></i>
                    <h3>Books are not Available</h3>
                </div>
            </div>

            <!-- PDF Documents Section -->
            <div class="pdf-section">
                <div class="pdf-container">
                    <!-- Document Items -->
                    <div v-for="doc in documents" :key="doc.id" class="pdf-item" @click="openDocument(doc.url)">
                        <div class="pdf-icon">
                            <svg viewBox="0 0 24 24" width="50" height="50" stroke="currentColor" fill="none" stroke-width="2">
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                                <polyline points="14 2 14 8 20 8" />
                                <line x1="16" y1="13" x2="8" y2="13" />
                                <line x1="16" y1="17" x2="8" y2="17" />
                                <line x1="10" y1="9" x2="8" y2="9" />
                            </svg>
                        </div>
                        <div class="pdf-title">{{ doc.title }}</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import CategoryFilter from '../../components/CategoryFilter.vue';
import EventSlider from '../../components/EventSlider.vue';

export default {
    name: 'LajnaDocuments',
    data() {
        return {
            documents: [
                // { id: 1, title: 'ഹദീസ് നിഷേധം', url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf' },
                // { id: 2, title: 'ഹദീസ് നിഷേധം', url: 'https://example.com/document2.pdf' },
                // { id: 3, title: 'ഹദീസ് നിഷേധം', url: 'https://example.com/document3.pdf' },
                // { id: 4, title: 'ഹദീസ് നിഷേധം', url: 'https://example.com/document4.pdf' }
            ]
        }
    },
    methods: {
        openDocument(url) {
            window.open(url, '_blank'); // Opens the document in a new tab
        }
    },
    components: {
        CategoryFilter,
        EventSlider
    }
}
</script>

<style scoped>
.pdf-section {
    background: white;
    border-radius: 0.5rem;
    padding: 1rem;
}

.pdf-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Always show 2 columns */
    gap: 1.5rem;
    padding: 1rem;
}

.pdf-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    padding: 0.5rem;
    transition: background-color 0.2s;
    border-radius: 0.5rem;
}

.pdf-item:hover {
    background-color: #f3f4f6;
}

.pdf-icon {
    color: #000;
}

.pdf-title {
    text-align: center;
    font-size: 1rem;
    color: #000;
}

.bottom-button {
    display: block;
    width: 100%;
    margin: 1rem auto;
    padding: 0.75rem 1.5rem;
    background: #0d4f17;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    text-align: center;
    text-decoration: none;
}

.bottom-button:hover {
    background-color: #0a3f12;
}

.no-books-message {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 270px;
    background: #f8f9fa;
    border-radius: 8px;
    margin: 1rem 0;
    border: 1px dashed #dee2e6;
}

.message-content {
    text-align: center;
    color: #6c757d;
}

.message-content i {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.message-content h3 {
    font-size: 1.2rem;
    margin: 0;
    font-weight: 500;
}
</style>
